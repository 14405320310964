/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Una de esas noticias poco interesantes para la mayoría de medios es la decisión del gobierno español de ", React.createElement(_components.a, {
    href: "http://postdigital.es/2014/01/03/jovenes-exiliados-perderan-sanidad-espana-p6729/"
  }, "dejar sin sanidad a los “jóvenes aventureros” que no encuentren trabajo"), ". Hasta este momento nunca había visto un ejemplo tan claro de lo que nos espera, disfrazado por la crisis se esconde el fantasma de la ", React.createElement(_components.a, {
    href: "http://lasindias.com/indianopedia/descomposicion"
  }, "descomposición"), ", esta noticia nos ofrece dos lecturas distintas y complementarias:"), "\n", React.createElement(_components.p, null, "Por un lado la aplicación brutal del ", React.createElement(_components.a, {
    href: "http://lasindias.com/indianopedia/principio-de-nacionalidad"
  }, "principio de nacionalidad"), ", por que la sanidad es uno de los pilares básicos de los estados europeos modernos, al negarte una de las coberturas básicas te están expulsando del sistema nacional, el mensaje es ”", React.createElement(_components.a, {
    href: "http://fondaki.com/hazte-extranjero/"
  }, "Vete al extranjero y deja de ser español"), "“."), "\n", React.createElement(_components.p, null, "Por el otro lado se sienta un precedente, la asociación del trabajo con la cobertura social, si no trabajas no tienes derecho a sanidad, educación, pensión…pero debes seguir pagando impuestos, una situación tan poco equilibrada entre estado y ciudadano no puede resultar en otra cosa que más descomposición. Se destruye la idea de protección estatal y con ella se destruye el estado."), "\n", React.createElement(_components.p, null, "Si hay remedio a esta descomposición acuciante sólo puede venir de sistemas de cobertura social comunitarios, podríamos hablar de los seguros privados de grandes corporaciones que el estado quiere imponer (sus amigotes), pero esto solamente es posponer el problema, llevan el mismo camino que los estados ¿no lo creéis? Sólo hay que ver ", React.createElement(_components.a, {
    href: "http://www.levante-emv.com/comunitat-valenciana/2013/04/30/llombart-advierte-rescatara-gestora-hospital-manises/994032.html"
  }, "los resultados que está dando la sanidad semi-privatizada"), " para hacerse una idea."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
